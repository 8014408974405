<template>
  <div class="flex flex-col">
    <p class="flex flex-row items-center text-lg font-semibold">
      <span class="text-base md:text-lg">Are you sure, you want to deactivate this ad?</span>
    </p>
    <p class="text-sm md:text-base pt-3 pb-5">
      By Deactivating, you will never be able to get this ad back active again.
      This will cancel any subscription or purchases made for this specific
      classified ad listing.
    </p>
    <div class="flex flex-row w-full">
      <BaseButton
      text-size-class="text-sm md:text-base"
      width-class="w-1/2"
      primary-color-class="a-error"
      text-color-class="white"
        margin-classes="my-2 mx-1"
        @click.native="deactivateClassifiedAd()"
        :loading="deactivateClassifiedAdLoading"
        loadingColor="#EF4444"
      >
        Yes, Deactivate
      </BaseButton>
      <BaseButton
      isTextOnly
      text-color-class="a-neutral-light"
      width-class="w-1/2"
      text-size-class="text-sm md:text-base"
      margin-classes="my-2 mx-1"
        @click.native="$emit('closeModal')"
      >
        No
      </BaseButton>
    </div>
  </div>
</template>

<script
  setup
  lang="ts"
>
import type { DashboardClassifiedAd } from '~/types';

const deactivateClassifiedAdLoading = ref(false);


const props = defineProps<{
  classifiedAd: DashboardClassifiedAd | null;
}>();

const emit = defineEmits(['closeModal']);

async function deactivateClassifiedAd() {
  deactivateClassifiedAdLoading.value = true;
  await useClassifiedDataManager().deactivateCurrentDashboardClassifiedAd();
  emit('closeModal');
  deactivateClassifiedAdLoading.value = false
}
</script>

<style></style>
